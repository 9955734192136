import React, { useState } from 'react';
import './ModelSwitch.css';

const ModelSwitch = ({ onModelChange }: { onModelChange: Function }) => {
    const [selectedModel, setSelectedModel] = useState('tuckbotgpt35');

    const handleModelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const model = event.target.value;
        setSelectedModel(model);
        onModelChange(model);
    };

    return (
        <div className="model-switch spacing">
            <label className="switch">
                <input
                    type="radio"
                    name="model"
                    value="tuckbotgpt35"
                    checked={selectedModel === 'tuckbotgpt35'}
                    onChange={handleModelChange}
                />
                <span className="slider"></span>
                <span className="label-text">ChatGPT 3.5</span>
            </label>
            <label className="switch">
                <input
                    type="radio"
                    name="model"
                    value="tuckbotgpt4o"
                    checked={selectedModel === 'tuckbotgpt4o'}
                    onChange={handleModelChange}
                />
                <span className="slider"></span>
                <span className="label-text">ChatGPT 4o</span>
            </label>
            <label className="switch">
                <input
                    type="radio"
                    name="model"
                    value="tuckbotgpt4omini"
                    checked={selectedModel === 'tuckbotgpt4omini'}
                    onChange={handleModelChange}
                />
                <span className="slider"></span>
                <span className="label-text">ChatGPT 4omini</span>
            </label>
        </div>
    );
};

export default ModelSwitch;